import { cloneDeep } from "lodash-es";
import { reactive } from "vue";
import type { Pack, PackFromAPI } from "~/types/Pack";

export function createEmptyPackage(): Pack {
  const dummyPack = reactive<Pack>({
    id: "",
    type: "",
    attributes: {
      dynamicPricing: {
        type: "normal",
        rules: [],
        mode: "per_pack",
        kidsPricePolicy: "",
      },
      quantity: 0,
      skipTimeSelection: false,
      noOfCourses: null,
      acceptedCards: [],
      acceptWeTravelTogether: false,
      availableMethods: "",
      ayceRules: [],
      chargePolicy: "",
      chargePolicyLink: "",
      chargeType: "",
      customLabel: {
        name: "",
        iconUrl: "",
      },
      customLabels: [],
      customNetPrice: "",
      customSeats: [],
      defaultStartTime: "",
      description: "",
      earnPoint: false,
      endDate: "",
      comemorePayless: false,
      forDineIn: false,
      hasCustomDeliveryFee: false,
      hhMenuV2HtmlPreviewLink: "",
      hideMenuPrice: false,
      imageCoverUrl: {
        large: "",
        thumb: "",
      },
      isAcceptManyQuantity: false,
      isAcceptVoucher: false,
      isAddOn: false,
      isAllowMix: false,
      isVisibleForStaff: false,
      kidsPriceV2: [],
      largestTable: 0,
      lastBookingWasMade: "",
      maxPackageQuantity: 0,
      menuLink: "",
      menuQuantityLimit: 0,
      menuSections: [],
      menuType: "img",
      name: "",
      openingHours: [],
      originalPrice: {
        currency: "",
        format: "",
        price: 0,
      },
      packageInfo: {
        kidsPriceRate: 0,
        maxPax: 0,
        minPax: 0,
        timeLimit: "",
        tncLink: "",
        perPack: 0,
      },
      paymentTypes: [],
      payNow: false,
      pricingMode: "",
      pricingType: "",
      pricingTypeSym: null,
      rankInRestaurantScope: 0,
      requireCc: false,
      restaurantCuisine: "",
      restaurantLocation: "",
      restaurantName: "",
      restaurantPackageVoucherDetail: "",
      restaurantPackageVoucherTnc: "",
      rules: [],
      slug: "",
      startDate: "2023-12-12",
      takeAwayProperty: {
        deliveryFeeCurrency: "",
        deliveryFeeInBaht: 0,
        deliveryFeePerKmInBaht: 0,
        deliveryRadius: 0,
        freeDeliveryFeeThresholdInBaht: 0,
      },
      timeSlots: {},
      typeCode: "ayce",
      typeName: "",
      useKidsPrice: false,
      bfpRules: {},
      pricingGroups: {},
      packageGroup: {},
      featured: false,
      packageCoverUrl: "",
    },
  });
  return dummyPack;
}

export function createPackInstance(pack: PackFromAPI): Pack {
  const newPack = cloneDeep(pack) as Pack;
  newPack.attributes.quantity = 0;
  return newPack;
}

export function createReactivePack(pack: PackFromAPI): Pack {
  return reactive(createPackInstance(pack));
}
