import { defineStore } from "pinia";
import { useReport } from "~/composables/useReport";
import { DEFAULT_ERROR_MESSAGE } from "~/constants";
import { convertToNumber } from "~/helpers/string";
import alert from "~/lib/alert";

export const useCorporateEventStore = defineStore("corporateEvent", {
  state: () => {
    return {
      eventId: "",
      eventForRestaurant: "" as string | number,
      isValid: false,
      isLoading: false,
      isSuccess: false,
      eventData: {
        id: "",
        type: "",
        attributes: {
          name: "",
          restaurantId: 0,
          date: "",
          restaurantSlug: "",
          startTime: "",
        },
        relationships: {
          restaurantPackages: {
            data: [] as Array<{ id: string; type: string }>,
          },
        },
      },
      eventPackages: [] as Array<string>,
    };
  },
  actions: {
    resetState() {
      this.eventId = "";
      this.eventForRestaurant = 0;
      this.isValid = false;
      this.isLoading = false;
      this.eventData = {
        id: "",
        type: "",
        attributes: {
          name: "",
          restaurantId: 0,
          date: "",
          restaurantSlug: "",
          startTime: "",
        },
        relationships: {
          restaurantPackages: {
            data: [],
          },
        },
      };
      this.eventPackages = [];
    },
    async validateEvent() {
      try {
        this.isLoading = true;
        const { validateEventId } = await import(
          "~/api/common/validateEventId"
        );
        const response = await validateEventId(this.eventId);
        if (response.success && response.data) {
          this.isSuccess = true;
          this.eventData = response.data;
          if (
            convertToNumber(this.eventData.attributes.restaurantId) ===
            convertToNumber(this.eventForRestaurant)
          ) {
            this.isValid = true;
            this.eventPackages =
              response.data.relationships.restaurantPackages?.data.map(
                (pack) => pack.id
              ) || [];
            this.isLoading = false;
            return;
          }
          this.isValid = false;
          this.eventPackages = [];
          this.isLoading = false;
          alert.error("You open wrong corporate restaurant");
          return;
        }
        this.resetState();
        this.isSuccess = false;
        this.isLoading = false;
      } catch (err) {
        const message = `${DEFAULT_ERROR_MESSAGE}, failed validate corporate event id`;
        useReport({
          level: "critical",
          message,
          errorException: err,
        });
      }
    },
  },
});
